const Apis = {
   'localhost': 'smartapi.smartintel.io',
   'smartintel.io': 'smartapi.smartintel.io',
   'mobica.smartintel.io': 'mobicaapi.smartintel.io',
   'apple.smartintel.io': 'appleapi.smartintel.io',
   'ishop.smartintel.io': 'ishopapi.smartintel.io',
   'penguin.smartintel.io': 'penguinapi.smartintel.io',
   'audi.smartintel.io': 'audiapi.smartintel.io',
   'app.smartintel.io': 'smartapi.smartintel.io',
   'test.smartintel.io': 'testapi.smartintel.io',
   'temp.smartintel.io': 'tempapi.smartintel.io',
   'expansion.smartintel.io': 'expansionapi.smartintel.io',
   'alpha.smartintel.io': 'alphaapi.smartintel.io',
   'asofarma.smartintel.io': 'asofarmaapi.smartintel.io',
   'valvolineapp.smartintel.io': 'valvolineapi.smartintel.io',
   'cetaphil.smartintel.io': 'cetaphilapi.smartintel.io',
   'bayer.smartintel.io': 'bayerapi.smartintel.io',
   'stives.smartintel.io': 'stivesapi.smartintel.io',
   'ace.smartintel.io': 'aceapi.smartintel.io',
   'byd.smartintel.io': 'bydapi.smartintel.io',
   'funomalls.smartintel.io': 'funomallsapi.smartintel.io'
}

module.exports = Apis